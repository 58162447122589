@font-face {
  font-family: "Sailec-Thin";
  src: url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.eot");
  src: url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.eot?#iefix")
      format("embedded-opentype"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.woff2")
      format("woff2"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.woff")
      format("woff"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.ttf")
      format("truetype"),
    url("//db.onlinewebfonts.com/t/b411263ff1ec9f3bdea1f4145d93ab18.svg#Sailec-Thin")
      format("svg");
}
@font-face{
  font-family: "Gilroy-Bold";
  src: url("./assets/font/Gilroy-Bold.woff");
}
@font-face{
  font-family: "Gilroy-Light";
  src: url("./assets/font/Gilroy-Light.otf");
}
@font-face{
  font-family: "Gilroy-Medium";
  src: url("./assets/font/Gilroy-Medium.woff");
}
@font-face{
  font-family: "Gilroy-Regular";
  src: url("./assets/font/Gilroy-Regular.woff");
}
@font-face{
  font-family: "Gilroy-Heavy";
  src: url("./assets/font/Gilroy-Heavy.woff");
}
body {
  margin: 0;
  font-family: "Gilroy-Regular";
  color: #1d1d47;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  scroll-behavior: smooth;
  height: 100%;
  font-size: 15px;
}
@media (max-width: 700px) {
  body {
    font-size: 14px;
    overflow-x: hidden;
  }
}
body {
  scroll-behavior: smooth;
  letter-spacing: normal;
}
.container-fluid {
  overflow-x: hidden;
}
.chc1{
  display: flex;
}
button:focus{
  outline: none;
}