@import url("https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap");
.selecting {
  font-weight: bold;
  font-size: 28px;
  line-height: 42px;
  color: #1d1d47;
  padding-bottom: 1rem;
  font-family: "gilroy-bold";
}
.requestdemo {
  padding-top: 1rem;
}
.rytcand {
  color: #ff647c;
}
.subtext {
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  width: 85%;
  color: #666666;
}

.reqdemo {
  font-weight: bold;
  padding: 0.7rem 1rem;
}
.reqdemo a {
  color: white;
}
.firstsec {
  padding-top: 12em;
  padding-left: 6rem;
}
.bluehome {
  width: 40px;
  position: relative;
  top: 3rem;
  right: 3rem;
}
.towimg {
  display: flex;
  justify-content: space-around;
}
.aall {
  height: min-content;
}
.bgrw1 {
  background: url("../../assets/firstimg.png");
  padding-bottom: 16rem;
  background-position-x: -1rem;
  background-size: contain;
  background-repeat: no-repeat;
}
.whyusewrapp {
  background: #1d1d47;
  color: #fff;
  padding: 6rem 6rem;
  padding-bottom: 1rem;
}
.didvere {
  padding-bottom: 2rem;
}
.whyuse {
  font-weight: 800;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 8px;
  font-weight: 800;
  font-family: "gilroy-bold";
  z-index: 3 !important;
}
.whouse {
  color: #ffffff;
}
.moretext {
  font-weight: normal;
  font-size: 18px;
  display: flex;
  font-family: "gilroy-light";
  align-items: center;
  letter-spacing: 0.7px;
  color: #ffffff;
  line-height: 1.6;
  padding-right: 4rem;
}
.moreoften {
  padding-bottom: 3rem;
  padding-top: 5rem;
}
.womaninmush1 {
  margin-top: 1rem;
}
.womaninmush {
  width: 54%;
}
.whyuseLigt {
  font-style: normal;
  font-weight: 800;
  font-size: 4.5em;
  letter-spacing: 8px;
  color: #ffffff;
  opacity: 0.05;
  position: relative;
  bottom: 3.4rem;
}
.whyuseLigt > span {
  position: absolute;
}
@media (min-width: 780px) {
  .whyuseLigt > span {
    width: 200%;
  }
}
.borderorange {
  border-left: 2px solid #ff647c;
  padding-left: 2rem;
  padding-top: 0px;
  padding-bottom: 0px;
}
.companieswuuseprecision {
  background: #1d1d47;
  padding: 0rem 4rem;
}
.companieswuuseprecision1 {
  background: #fff;
  padding: 1rem 6rem;
  padding-top: 5rem;
  padding-bottom: 3rem;
}
.stepsheader {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.1px;
  line-height: 28px;
  color: #333333;
  padding-top: 1.6rem;
}
.fassde {
  color: #e4e4e4 !important;
  opacity: 0.6;
  font-size: 1.9em !important;
  z-index: 0;
  font-family: "gilroy-heavy";
  top: -1.4rem;
  z-index: 0;
}
.fassde1 {
  color: rgba(153, 153, 153, 0.5);
  opacity: 0.6;
  font-size: 1.9em !important;
  top: -1.2rem;
  z-index: 1;
  font-family: "gilroy-heavy" !important;
}
.imglist {
  padding-top: 3rem;
  display: flex;
  justify-content: space-around;
}
.imgitem {
  margin-right: 85px;
}
.partnerlist {
  display: flex;
  flex-direction: row;
}
.partners {
  width: 14vw;
}
.orrange {
  border-bottom: 4px solid #ff647c;
  padding-bottom: 1rem;
}
.wg1 {
  padding-bottom: 3rem;
}
.howitwrap {
  display: flex;
  padding-top: 4rem;
  justify-content: space-between;
}
.hiwork {
  font-weight: normal;
  font-size: 18px;
  line-height: 32px;
  text-align: center;
  color: #333333;
  width: 350px;
  text-align: center;
  margin: auto;
  margin-top: 3rem;
  padding-right: 1rem;
}
.howitimgarea {
  text-align: center;
}
.thumbsup {
  width: 110px;
}
.arrows {
  display: flex;
  justify-content: space-evenly;
  position: relative;
  top: 8rem;
}
.rytarrow {
  width: 12vw;
}

.folzzy {
  background: url("../../assets/marks.png") #e5e5e5a4;
  padding-top: 8rem;
  padding-bottom: 5rem;
  background-size: contain;
}
.folzzypaypage {
  background: url("../../assets/marks.png") #fff;
  padding: 4rem;
  padding-top: 6rem;
  background-size: contain;
}
.tble1 {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.firsttablehead {
  background: #0084f4;
  border-radius: 8px 8px 0px 0px;
  font-weight: bold;
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  padding: 2rem;
  color: #ffffff;
}
.bluetbl2 {
  margin-left: 3rem;
  width: 513px;
}
.bluetbl {
  width: 513px;
}
.regularwhite {
  background: #ffffff;
  box-shadow: 2px 8px 16px rgba(50, 50, 71, 0.12);
  border-radius: 8px;
  font-weight: 500;
  font-size: 18px;
  line-height: 23px;
  color: #1d1d47;
  padding: 1rem;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-bottom: 2rem;
  padding-right: 4rem;
}
.timescode {
  color: #ff0000;
  padding-right: 1.5rem;
  font-size: 1.4em;
  margin-bottom: 0px;
}
.secondthead {
  display: flex;
  padding-bottom: 1.5rem;
}
.rightsign {
  width: 24px;
}
.whichdoyou {
  font-family: "Luckiest Guy", cursive;
  color: #fff;
  font-size: 2.8em;
  background: #00c48c;
  letter-spacing: 0.055em;
  justify-content: center;
  text-align: center;
  padding: 2rem;
  margin-bottom: 3rem;
}
.signoutw1 {
  padding: 0.7rem 2.3rem;
}
.getstarted2 {
  font-size: 0.5em;
  letter-spacing: 0.2px;
  font-family: "gilroy-medium";
}
.firstpayment {
  padding: 4rem;
}
.seelect {
  letter-spacing: 0.5px;
  font-size: 13px;
  color: #666666;
  padding-top: 0.4rem;
}
.wrappf {
  display: flex;
}
.selectins {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  display: flex;
  align-items: center;
  color: #1d1d47;
  width: 30%;
  padding-right: 1rem;
}
.selectplan1 {
  width: 56%;
  padding-top: 2rem;
  min-width: 300px;
}
.hireplace {
  font-weight: 800;
  font-size: 27px;
  line-height: 40px;
  letter-spacing: 2px;
  margin-top: 16rem;
  color: #1d1d47;
}
.secpay {
  font-weight: 600;
  font-size: 22px;
  line-height: 29px;
  display: flex;
  align-items: center;
  letter-spacing: 0.5px;
  color: #1d1d47;
}
.proceed1 {
  color: #fff;
  background: linear-gradient(225deg, #a6d6ff 0%, #0084f4 100%);
  border-radius: 8px;
  border: none;
  padding: 0.7rem 0rem;
  width: 140px;
  font-weight: bold;
  text-align: center;
}
.backhome {
  margin-right: 32px;
}
.amountcheck {
  font-weight: 800;
  font-size: 27px;
  line-height: 49px;
  letter-spacing: 2px;
  color: #1d1d47;
  padding-bottom: 2rem;
}
.amount22 {
  padding-top: 3rem;
  padding-left: 4.5rem;
}
.dls {
  display: flex;
  justify-content: flex-end;
  padding-top: 4rem;
  padding-right: 1rem;
}
.custom-select {
  position: relative;
  font-family: Arial;
  font-weight: 900;
}
.selectoption {
  background: #1d1d47;
  border-radius: 8px;
  padding: 3rem !important;
  height: 54px !important;
  font-weight: 900;
}

.selectoption:hover {
  background: inherit;
}
.nairaa {
  padding: 2rem;
}
.noofpersons {
  font-weight: 600;
  font-size: 18px;
  line-height: 38px;
  color: #999999;
}

.btnws {
  font-size: 12px;
  padding: 0.4rem 0.6rem;
  border-radius: 1rem;
  min-width: 80px;
}
.profgr {
  background: none;
  color: #0084f4;
  margin-left: 0.5rem;
}

/* Mobile Responsiveness for payment page*/
@media (max-width: 1000px) {
  .arrows {
    display: none;
  }
  .howitwrap {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
  .selectins, .secpay {
    font-size: 20px;
    line-height: 26px;
  }
  .noofpersons {
    font-size: 16px;
    line-height: 35px;
  }
  .amount22 {
    padding-top: 5rem;
    padding-left: 1.5rem;
  }
  .amountcheck {
    font-size: 22px;
  }
  .woman {
    width: 56%;
    margin-top: 45px;
  }
  .proceed1 {
    width: 110px;
    font-size: 13px;
  }
  .folzzypaypage {
    padding-top: 0px;
  }
  .hireplace {
    margin-top: -2rem;
    font-size: 22px;
    line-height: 35px;
    letter-spacing: 1px;
  }
  .backhome {
    margin-right: 162px;
  }
  .why {
    margin-top: 3rem;
  }
}

@media (max-width: 800px) {
  .selectins, .secpay {
    font-size: 19px;
    line-height: 25px;
  }
  .secpay {
    margin-top: -40px;
  }
  .noofpersons {
    font-size: 15px;
  }
  .seelect {
    font-size: 12px;
  }
  .amountcheck {
    font-size: 22px;
  }
  .proceed1 {
    width: 100px;
  }
  .amount22 {
    padding-top: 2rem;
  }
  .hireplace {
    font-size: 20px;
  }
  .folzzypaypage {
    padding: 1rem;
  }
  .txt {
    font-size: 13.3px !important;
  }
  .backhome {
    margin-right: 67px;
  }
}

@media (max-width: 767px) {
  .woman {
    display: none;
  }
}

@media (max-width: 600px) {
  .firstpayment {
    padding: 2rem;
  }
  .slct {
    margin-top: 20px;
    padding-right: 2.5rem;
  }
  .selectplan1 {
    width: 60%;
    padding-right: 10px;
  }
  .selectins, .secpay {
    font-size: 17px;
  }
  .noofpersons {
    font-size: 12px;
    width: 80%;
    height: 27px;
  }
  .seelect {
    font-size: 11px;
    letter-spacing: 0.3px;
    width: 80%
  }
  .amountcheck {
    font-size: 20px;
  }
  .proceed1 {
    width: 80px;
    font-size: 12px;
  }
  .hireplace {
    margin-top: -4rem;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.8px;
  }
  .backhome {
    width: 82px;
    font-size: 11px;
    margin-right: 42px;
  }
}

@media (max-width: 400px) {
  .selectins, .secpay {
    font-size: 15px;
  }
  .selectplan1 {
    margin-left: 10px;
  }
  .noofpersons, .seelect {
    font-size: 10px;
    width: 70%;
  }
  .noofpersons {
    height: 25px;
  }
  .amount22 {
    padding-top: 1rem;
  }
  .amountcheck {
    font-size: 17px;
    padding-bottom: 1rem;
  }
  .proceed1 {
    width: 68px;
    font-size: 10px;
  }
  .hireplace {
    font-size: 16px;
  }
  .folzzypaypage {
    padding: 0rem;
  }
  .whyhead {
    font-size: 18.5px !important;
  }
  .backhome {
    font-size: 10px;
    width: 77px;
    margin-right: 34px;
    margin-top: -1rem;
    margin-bottom: 2.5rem;
  }
}

/* Mobile Responsiveness for landing page*/
@media (max-width: 1200px) and (min-width: 1001px) {
  .bgrw1 {
    padding-bottom: 6rem;
  }
  .firstsec {
    padding-top: 8em;
  }
  .selecting {
    width: 130%;
  }
  .subtext {
    width: 34vw;
  }
  .whyuse {
    font-size: 3.2vw;
  }
  .whyuseLigt {
    font-size: 5.5vw;
  }
  .womaninmush1 {
    margin-top: 5rem;
  }
  .fassde1 {
    font-size: 4.6vw !important;
  }
  .imgitem {
    margin-right: 65px;
  }
  .bluetbl {
    width: 450px;
  }
  .bluetbl2 {
    margin-left: 3rem;
    width: 450px;
  }
  .hiwork {
    width: 25vw;
  }
}

@media (max-width: 1000px) {
  .bgrw1 {
    background: url("../../assets/mobile_bg.svg");
    padding-bottom: 7rem;
  }
  .selecting {
    font-size: 27px;
  }
  .subtext,
  .moretext,
  .stepsheader,
  .hiwork,
  .regularwhite {
    font-size: 17px;
  }
  .firstsec {
    padding-top: 8em;
  }
  .firstareawrap {
    width: 75vw;
  }
  .whyuseLigt {
    letter-spacing: 7px;
  }
  .womaninmush1 {
    display: none;
  }
  .moreoften {
    padding-top: 3rem;
  }
  .moretext {
    width: 85vw;
  }
  .whyuse {
    width: 80vw;
    font-size: 4vw;
    letter-spacing: 3px;
  }
  .imgitem {
    margin-right: 60px;
  }
  .whouse {
    padding-top: 0.4rem;
  }
  .fassde1 {
    letter-spacing: 10px;
  }
  .precision_del {
    display: none;
  }
  .howitwrap {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .howitimgarea {
    margin-bottom: 50px;
  }
  .firsttablehead {
    font-size: 27px;
  }
  .tble1 {
    flex-direction: column;
    align-items: center;
  }
  .using {
    display: inline;
  }
  .bluetbl2 {
    margin-top: 5rem;
    margin-left: 0px;
  }
}

@media (max-width: 960px) {
  .whyuseLigt {
    font-size: 6.5vw;
  }
}

@media (max-width: 900px) {
  .selecting {
    width: 83vw;
  }
  .firstareawrap {
    width: 85vw;
  }
  .whyuseLigt {
    bottom: 3rem;
    letter-spacing: 6px;
  }
  .whichdoyou {
    font-size: 2.5em;
  }
}

@media (max-width: 911px) {
  .orrange {
    border-bottom: none;
  }
  .stepsheader {
    display: flex;
    flex-direction: column;
  }
  .stepsheader::after {
    content: " ";
    border-bottom: 4px solid #ff647c;
    display: inline-block;
    width: 100px;
    margin-top: 12px;
  }
  .works_del {
    display: none;
  }
  .whichdoyou {
    font-size: 2.4em;
  }
}

@media (max-width: 800px) {
  .firstsec {
    padding-left: 5rem;
  }
  .whyusewrapp {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .companieswuuseprecision {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .companieswuuseprecision1 {
    padding-left: 4rem;
    padding-right: 4rem;
    padding-bottom: 1rem;
  }
  .fassde1 {
    font-size: 3.4rem !important;
    letter-spacing: 3px;
  }
  .imglist {
    padding-top: 0rem;
    flex-direction: column;
  }
  .partnerlist {
    display: flex;
    justify-content: space-around;
    padding-top: 2.5rem;
  }
  .imgitem {
    margin-right: 0px;
  }
  .partners {
    width: 18vw;
  }
  .bluetbl,
  .bluetbl2 {
    width: 75%;
  }
  .thumbsup {
    width: 95px;
  }
  .subtext,
  .moretext,
  .stepsheader,
  .hiwork,
  .regularwhite {
    font-size: 16px;
  }
  .folzzy {
    padding-top: 5rem;
  }
  .firsttablehead, .selecting {
    font-size: 23px;
  }
  .firsttablehead {
    padding: 1.3rem;
  }
  .regularwhite {
    padding-top: 2rem;
    line-height: 22px;
  }
  .whichdoyou {
    font-size: 2.05rem;
  }
}

@media (max-width: 705px) {
  .use_del {
    display: none;
  }
  .regularwhite {
    padding-right: 3rem;
  }
  .partners {
    width: 20vw;
  }
  .selectins {
    width: 100% !important;
  }
  .wrappf {
    flex-wrap: wrap;
  }
  .firstpayment {
    padding: 2rem;
    padding-top: 4rem;
  }
  .amount22 {
    padding-left: 0px;
  }
  .secpay {
    padding-top: 0.5rem;
  }
  .amountcheck {
    padding-bottom: 1rem;
  }
  .folzzypaypage {
    padding: 0rem;
  }
  .bluetbl,
  .bluetbl2 {
    width: 85%;
  }
  .dls {
    padding-top: 2rem;
    padding-bottom: 1rem;
    justify-content: center;
  }
  .proceed1 {
    padding: 0.8rem 1.5rem;
    width: 172px;
  }
}

@media (max-width: 600px) {
  .firstsec {
    padding-left: 3.5rem;
  }
  .whyusewrapp {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .companieswuuseprecision {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .companieswuuseprecision1 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .signoutw1 {
    padding: 0.2rem 1.8rem;
  }
  .moretext {
    width: 90vw;
  }
  .partners {
    width: 23vw;
  }
  .partnerlist {
    padding-top: 1.5rem;
  }
  .subtext,
  .moretext,
  .stepsheader,
  .hiwork,
  .regularwhite {
    font-size: 15px;
  }
  .folzzy {
    padding-top: 4rem;
  }
  .firsttablehead {
    font-size: 21px;
    padding: 1rem;
  }
  .regularwhite {
    padding-right: 2rem;
    padding-left: 1rem;
    line-height: 18px;
    padding-top: 1.5rem;
  }
  .whichdoyou {
    font-size: 1.5rem;
  }
  /*payment page*/
  .why {
    margin-top: 1.5rem;
  }
}

@media (max-width: 500px) {
  .selecting {
    width: 85vw;
  }
  .subtext {
    width: 80vw;
  }
  .firstsec {
    padding-left: 3rem;
  }
  .fassde1,
  .fassde {
    font-size: 149% !important;
    letter-spacing: 2px;
  }
  .whouse {
    padding-top: 0px;
    font-size: 121%;
  }
  .partners {
    width: 28vw;
  }
}

@media (max-width: 400px) {
  .selecting {
    width: 85vw;
    font-size: 23px;
  }
  .subtext {
    font-size: 14px;
  }
  .signoutw1 {
    padding: 0.1rem 1.1rem;
  }
  .partners {
    width: 30vw;
  }
  .whyuse {
    margin-top: -2rem;
    font-size: 5vw;
    width: 90vw;
  }
  .moretext {
    font-size: 14px;
    width: 95vw;
  }
  .stepsheader {
    font-size: 14px;
  }
  .thumbsup {
    width: 90px;
  }
  .hiwork {
    font-size: 14px;
    width: auto;
  }
  .firsttablehead {
    font-size: 20px;
  }
  .regularwhite {
    font-size: 14px;
    line-height: 18px;
    padding-top: 1rem;
  }
  /*payment page*/
  .why {
    margin-top: 1rem;
  }
}
